export const MAX_AMOUNT = 600;
const CLICK_PRICE = 50;

export const useGameStore = defineStore('hamster/game', () => {
  const clicks = ref(0);

  const rubles = computed(() => Math.min(MAX_AMOUNT, clicks.value * CLICK_PRICE));
  const isWon = computed(() => rubles.value >= MAX_AMOUNT);

  return {
    clicks,
    isWon,
    rubles,
  };
});
